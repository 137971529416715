import {CompanyStatus} from 'domain/Company'
import {Locale} from 'domain/Locale'
import {Permission} from 'domain/Permission'
import {ProjectLinkFormat, ProjectReportSize, ProjectType, REQUIRED_REPORTS_SIZES_BY_TYPE} from 'domain/Project'
import {PublishingProjectTask} from 'domain/Task'
import {ReactComponent as PublishIcon} from '../../../assets/icons/arrow_upward.svg'
import React, {useEffect, useState} from 'react'
import ReactDOM from 'react-dom/server'
import {useTranslation} from 'react-i18next'
import {getFile} from '../../../api-clients/fileClient'
import {publishProjectReport} from '../../../api-clients/projectClient'
import useModal from '../../../hooks/useModal'
import ConfirmationModal from '../../utils/ConfirmationModal'
import FormattedDate from '../../utils/FormattedDate'
import Protected from '../../utils/Protected'
import OutdatedReportFilesWarning from './OutdatedReportFilesWarning'
import RegenerateLastReportButton from './RegenerateLastReportButton'
import RegenerateReportButton from './RegenerateReportButton'
import UnpublishReportButton from './UnpublishReportButton'
import {ProjectAggregate} from 'domain/ProjectAggregate'

interface PublishedProjectLinksProps {
  project: ProjectAggregate
  task: PublishingProjectTask
  loadProject: () => void
}

const PublishedProjectLinks: React.FC<PublishedProjectLinksProps> = props => {
  const {t} = useTranslation()
  const {project, task, loadProject} = props
  const {showModal} = useModal()
  const [published, setPublished] = useState<{[key in Locale]: boolean}>({
    [Locale.EN]: !!project.published?.en,
    [Locale.JA]: !!project.published?.ja
  })

  const [inProgress, setInProgress] = useState<{[key in Locale]: boolean}>({
    [Locale.EN]: !!task.isGeneratingFiles?.en,
    [Locale.JA]: !!task.isGeneratingFiles?.ja
  })

  useEffect(() => {
    setPublished({
      [Locale.EN]: !!project.published?.en,
      [Locale.JA]: !!project.published?.ja
    })
  }, [project])

  useEffect(() => {
    setInProgress({
      [Locale.EN]: !!task.isGeneratingFiles?.en,
      [Locale.JA]: !!task.isGeneratingFiles?.ja
    })
  }, [task])

  const downloadPdf = async (event: React.MouseEvent<HTMLAnchorElement>, filename: string) => {
    const anchor = event.target as HTMLAnchorElement
    if (!anchor.href.endsWith('#')) {
      return
    }

    event.preventDefault()
    const blob = await getFile(filename)
    anchor.href = URL.createObjectURL(blob)
    setTimeout(() => anchor.click())
  }

  const publishReport = (event: React.MouseEvent, locale: Locale) => {
    const publishedAt = project.publishedAt?.[locale]
      ? ReactDOM.renderToString(<FormattedDate date={project.publishedAt?.[locale]!} />)
      : t('components.PublishedProjectLinks.publishConfirmation.today')

    const onConfirm = async () => {
      setInProgress({...inProgress, [locale]: true})
      publishProjectReport(project._id, locale)
        .then(() => {
          setPublished({...published, [locale]: true})
          loadProject()
        })
        .catch(_ => {
          setPublished({...published, [locale]: false})
        })
        .finally(() => setInProgress({...inProgress, [locale]: false}))
    }

    showModal(
      <ConfirmationModal
        title={t('components.PublishedProjectLinks.publishConfirmation.title')}
        body={t('components.PublishedProjectLinks.publishConfirmation.message', {
          type: t('components.PublishedProjectLinks.publishConfirmation.' + locale),
          publishedAt
        })}
        ConfirmationIcon={PublishIcon}
        onConfirm={onConfirm}
      />
    )
  }

  return (
    <div className="data-list p-2">
      <>
        {[Locale.JA, Locale.EN].map(locale => {
          const pdfLinks = project.links?.filter(link => link.locale === locale && link.format === ProjectLinkFormat.PDF)
          const isAllPdfGenerated = (pdfLinks?.length || 0) >= REQUIRED_REPORTS_SIZES_BY_TYPE[project.type].length
          const isSomePdfGenerated = (pdfLinks?.length || 0) > 0
          const disablePublishing = project.isFinished || inProgress[locale] || !isAllPdfGenerated

          return (
            <div className="d-flex align-items-middle justify-content-between mb-4" key={locale}>
              <div className="data-list data-list-row small w-100">
                <dl className="w-75">
                  <dt>{t('components.PublishedProjectLinks.reportLink.' + locale)}</dt>
                  <dd>
                    <div className="d-flex">
                      <div>
                        {[ProjectReportSize.REGULAR, ProjectReportSize.FULL, ProjectReportSize.TEASER].map(size => {
                          const pdfLink = project.links
                            ?.filter(link => link.locale === locale && link.format === ProjectLinkFormat.PDF && link.size === size)
                            ?.first()

                          if (!pdfLink) return <React.Fragment key={`${locale}-${size}`} />
                          return (
                            <div key={`${locale}-${size}`}>
                              {t(`models.ProjectReportSize.${size}`)}:{' '}
                              <a target="_blank" href="#" download={pdfLink.filename} onClick={e => downloadPdf(e, pdfLink.filepath)}>
                                {pdfLink.filename}
                              </a>
                            </div>
                          )
                        })}

                        {!pdfLinks?.length && t('components.PublishedProjectLinks.noReports')}
                        {project.type === ProjectType.NEWS_UPDATE && (
                          <Protected permission={Permission.Project.EDIT_SETTINGS}>
                            <RegenerateLastReportButton project={project} locale={locale} task={task} />
                          </Protected>
                        )}
                      </div>

                      <RegenerateReportButton
                        project={project}
                        locale={locale}
                        loadProject={loadProject}
                        regenerationInProgress={inProgress[locale]}
                        disabled={!!project.isFinished}
                      />
                    </div>

                    {!published[locale] && !isAllPdfGenerated && isSomePdfGenerated && (
                      <span className="text-danger">{t('components.PublishedProjectLinks.regenerateReports')}</span>
                    )}

                    {pdfLinks && pdfLinks.length > 0 && (
                      <Protected permission={Permission.Project.EDIT_SETTINGS}>
                        <OutdatedReportFilesWarning project={project} locale={locale} reportFileCreatedAt={new Date(pdfLinks[0]!.createdAt)} />
                      </Protected>
                    )}
                  </dd>
                </dl>
                <dl>
                  <dt>{t('components.PublishedProjectLinks.publishedAt')}</dt>
                  <dd>{project.publishedAt?.[locale] ? <FormattedDate date={project.publishedAt[locale]!} /> : '-'}</dd>
                </dl>
              </div>
              <div>
                {project.company.status === CompanyStatus.ACTIVE && (
                  <Protected permission={Permission.Project.EDIT_SETTINGS}>
                    {published[locale] ? (
                      <UnpublishReportButton project={project} locale={locale} loadProject={loadProject} disabled={!!project.isFinished} />
                    ) : (
                      <button
                        className="btn btn-xs btn-success btn-with-icon text-nowrap"
                        disabled={disablePublishing}
                        onClick={e => publishReport(e, locale)}
                      >
                        {inProgress[locale] && <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true" />}
                        <span>{t('components.PublishedProjectLinks.publishLink')}</span>
                        <PublishIcon className="icon-xs" />
                      </button>
                    )}
                  </Protected>
                )}
              </div>
            </div>
          )
        })}
      </>
    </div>
  )
}

export default PublishedProjectLinks
