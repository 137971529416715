import {Locale} from 'domain/Locale'
import {GroupedNews} from 'domain/Project'
import {Block, BlockType, Section} from 'domain/Report'
import React, {ReactNode} from 'react'
import LazyGradually from '../../utils/LazyGradually'
import SectionOrNewsReleases from '../edit/SectionOrNewsReleases'
import ReadonlyBlocks from '../view/ReadonlyBlocks'

interface SectionContentProps {
  locale: Locale
  lazy: boolean
  blocksById: Record<string, Block>
  section: Section
  displayIfNoNews: boolean
  groupedNews?: GroupedNews
}

const SectionContent: React.FC<SectionContentProps> = props => {
  const {section, locale, groupedNews, blocksById, lazy, displayIfNoNews} = props
  const withLazy = (content: ReactNode) => {
    return lazy ? <LazyGradually>{content}</LazyGradually> : content
  }

  const sectionBlocks = (): Block[] => {
    return section.blocks
      .map(block => {
        if (block.type === BlockType.CLONE) {
          return blocksById[block.sourceId] ? {...blocksById[block.sourceId], id: block.id} : null
        } else {
          return block
        }
      })
      .filter(b => !!b) as Block[]
  }

  const render = () => {
    return (
      <div className="report-section report-section-output section" key={`section-${section._id}`} id={section._id as string} data-section={true}>
        <div className="container">
          <div className="report-view">{withLazy(<ReadonlyBlocks locale={locale} displayBookmarks={false} blocks={sectionBlocks()} />)}</div>
        </div>
      </div>
    )
  }

  return (
    <SectionOrNewsReleases
      key={section._id}
      section={section}
      groupedNews={groupedNews}
      locale={locale}
      displayIfNoNews={displayIfNoNews}
      render={render}
    />
  )
}

export default SectionContent
